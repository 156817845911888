@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family: "Varela Round", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgb(49, 49, 49);
}

::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 5px;
}
