body {
  background-color: rgb(253, 248, 255);
}

textarea {
  color: whitesmoke;
}

a {
  text-decoration: none;
}

.app {
  overflow-x: hidden;
}
