.navbar {
  height: 4.5rem;
  background-color: black;
  position: sticky;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  color: whitesmoke;
  font-weight: 900;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  position: fixed;
  z-index: 2;
}

.nav-items-left {
  display: flex;
  align-items: center;
}

.nav-username {
  font-family: "Playpen Sans", cursive;
  font-size: 1.5rem;
  margin-left: 2rem;
}

.nav-intro,
.nav-achievements,
.nav-projects,
.nav-about,
.nav-contact {
  margin-right: 1rem;
  cursor: pointer;
}
.nav-intro:hover,
.nav-achievements:hover,
.nav-projects:hover,
.nav-about:hover,
.nav-contact:hover {
  color: rgb(78, 78, 220);
}
.navbar li {
  list-style: none;
}

.nav-items {
  display: flex;
  margin-right: 1.5rem;
}

.logo-img {
  height: 2.5rem;
  border: solid black;
  margin-left: 2rem;
  margin-right: -1rem;
}

.hamburger-icon {
  margin-right: 2.5rem;
  display: none;
}

@media (max-width: 800px) {
  .hamburger-container {
    height: 10rem;
    width: 5rem;
    margin-top: 9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .nav-username {
    font-size: 1rem;
  }
  .logo-img {
    height: 2rem;
  }
  .hamburger-icon {
    display: block;
    margin-top: -9rem;
    margin-left: 1.7rem;
  }
  .nav-items {
    flex-direction: column;
    margin-left: 0rem;
    position: absolute;
    margin-top: -0.5rem;
    justify-content: center;
    align-items: center;
    background-color: rgb(41, 41, 41);
    height: 6rem;
    width: 6rem;
    display: none;
  }
  .nav-items ul {
    font-size: 0.6rem;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    text-align: center;
  }
}
