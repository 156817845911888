@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");
.achievements-section {
  height: 44.8rem;
  background-color: rgb(22, 22, 22);
  display: flex;
  justify-content: center;
  align-items: center;
}
.achievements-heading {
  font-size: 4rem;
  font-weight: bolder;
  color: aliceblue;
  font-family: "Lilita One", sans-serif;
  margin-left: 10rem;
  margin-top: 4rem;
}

.achievements-contents {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.mySwiper {
  height: 32rem;
  color: whitesmoke;
  width: 50rem;
  margin-top: 0rem;
}

.achievement-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: c;
}
.icpc-img {
  height: 10rem;
  width: 15rem;
  margin: 6rem auto;
}

.achievement-1-text {
  font-family: "Poppins", sans-serif;
  color: aliceblue;
  margin: -1rem auto;
  font-size: 1.5rem;
}

.achievement-1-innertext {
  margin: 2rem auto;
  color: rgb(179, 184, 188);
}

.achievement-2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 30rem;
  margin-left: 10rem;
}

.solve-count-png {
  height: 8rem;
  width: 29rem;
  margin: 9rem auto;
  border-radius: 2rem;
  box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075),
    0 0 0 1px hsla(0, 0%, 0%, 0.05), 0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
    0 0.9px 1.5px hsla(0, 0%, 0%, 0.045), 0 3.5px 6px hsla(0, 0%, 0%, 0.09);
  filter: invert(90%);
}

.achievement-2-heading {
  font-family: "Poppins", sans-serif;
  color: aliceblue;
  margin: -7rem auto;
  font-size: 1.5rem;
  text-align: center;
}

.achievement-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cp-rating-img {
  height: 13rem;
  width: 28rem;
  margin: 7rem auto;
  border-radius: 2rem;
  box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075),
    0 0 0 1px hsla(0, 0%, 0%, 0.05), 0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
    0 0.9px 1.5px hsla(0, 0%, 0%, 0.045), 0 3.5px 6px hsla(0, 0%, 0%, 0.09);
  filter: invert(90%);
}

.achievement-3-heading {
  font-family: "Poppins", sans-serif;
  color: aliceblue;
  margin: -5rem auto;
  font-size: 1.5rem;
}

.achievement-3-text {
  margin: 6rem auto;
  text-align: center;
  color: rgb(179, 184, 188);
}

@media (max-width: 800px) {
  .achievements-heading {
    margin: auto;
    font-size: 2rem;
  }
  .achievements-contents {
    width: 20rem;
    height: 30rem;
    margin-top: -1rem;
  }
  .mySwiper {
    height: 20rem;
    width: 20rem;
  }
  .icpc-img {
    height: 3rem;
    width: 8rem;
    margin-top: 0rem;
  }
  .achievement-1-text {
    font-size: 1rem;
  }
  .achievement-2-heading {
    font-size: 1rem;
    margin-left: -10rem;
    margin-top: 5rem;
    width: 20rem;
  }
  .solve-count-png {
    height: 7rem;
    width: 15rem;
    margin: 0rem;
    margin-left: -7.5rem;
  }
  .cp-rating-img {
    height: 7rem;
    width: 15rem;
    margin-top: 0rem;
  }
  .achievement-2 {
    display: flex;
    flex-direction: column;
  }
}
