@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;1,900&display=swap");

.about-section {
  height: 44.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-contents {
  display: flex;
  justify-content: center;
}

.coffee-img {
  height: 22rem;
  width: 30rem;
  border-radius: 2rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* margin-left: 3rem; */
  margin-top: 7rem;
}

.about-text {
  margin-left: 8rem;
  margin-top: 5rem;
  font-family: "Archivo", sans-serif;
}

.about-start {
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
  margin-top: 0.5rem;
}

.about-heading {
  color: blueviolet;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.about-body {
  /* border: solid red; */
  width: 25rem;
  margin-top: 2rem;
  color: #636363;
  line-height: 1.5;
}

.white-circle {
  position: absolute;
  height: 10rem;
  background-color: rgb(235, 218, 239);
  z-index: 1;
  /* border: solid red; */
  margin-top: -8rem;
  margin-left: 24rem;
  width: 10rem;
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  animation-name: rotate;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.astronaut-icon {
  margin-top: -2rem;
  height: 8rem;
  margin-left: -0.4rem;
}

@media (max-width: 800px) {
  .about-section {
    height: 55rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about-contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .coffee-img {
    margin-top: 1rem;
    height: 13rem;
    width: 15rem;
  }
  .coffee-img-contents {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .white-circle {
    margin-top: 11rem;
    width: 6rem;
    height: 6rem;
    margin-right: 10rem;
  }
  .astronaut-icon {
    height: 5rem;
  }
  .about-heading {
    font-size: 0.7rem;
  }
  .about-start {
    font-size: 1rem;
  }
  .about-body {
    font-size: 0.8rem;
    width: 20rem;
  }
  .about-text {
    margin-left: 1rem;
  }
}
